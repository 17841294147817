import { IResidential } from "../../../types/residentials";
import City from "../../../types/city";
import ActionTypes from "../types";

export const setCurrentSity = (city: City) => ({
    type: ActionTypes.SET_CURRENT_SITY,
    payload: city
})

export const setCurrentResidential = (residential: IResidential) => ({
    type: ActionTypes.SET_CURRENT_RESIDENTIAL,
    payload: residential
})