import { FC, useEffect, useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import pxIntoRem from "../utils/remIntoPx";
import DefaultLayout from "../layouts/DefaultLayout";
import City from "../types/city";
import residentialsService from "../services/residentialsService";
import { IResidential } from "../types/residentials";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../store";
import { setCurrentResidential } from "../store/residentialsReducer/actions";
import { formatCost } from "../utils/costFormatters";
import statisticService from "../services/statisticService";

const ResidentialsBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  flex-grow: 1;
`;

const ResidentialsHeader = styled.header`
  display: flex;
  align-items: center;
  padding: 30px 0px 25px;
  border-bottom: 1px solid #e4e4e4;
  width: 100%;
`;

const ResidentialsHeaderBack = styled.button`
  width: 14.23px;
  height: 11px;
  margin-right: 19px;
  cursor: pointer;
  background-color: transparent;
`;

const ResidentialsHeaderBackImage = styled.img`
  width: 100%;
  height: 100%;
`;

const ResidentialsHeaderTitle = styled.h1`
  font-family: "Averta CY";
  font-style: normal;
  font-weight: 400;
  // font-size: 20px;
  font-size: ${pxIntoRem(20)};
  line-height: 25px;
  text-align: center;
  color: #000000;
`;

const ResidentialsList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 60px;
`;

const ResidentialsResidential = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  height: 201px;
  overflow: hidden;
  margin-top: 16px;
  cursor: pointer;
  &:first-of-type {
    margin-top: 0px;
  }
  @media (max-width: 400px) {
    height: auto;
    max-height: 201px;
  }
`;

const ResidentialsResidentialImageBlock = styled.div`
  width: 100%;
  height: 32vmin;
  max-height: 136px;
  flex-shrink: 0;
  // overflow: hidden;
`;

const ResidentialsResidentialImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: white;
`;

const ResidentialsResidentialBody = styled.div`
  display: flex;
  flex-grow: 1;
  max-height: 65px;
  padding: 11px 15px 14px 14px;
`;

const ResidentialsResidentialBodyColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0;
  flex-grow: 1;

  &:first-of-type {
    align-items: flex-start;
  }

  &:last-of-type {
    align-items: flex-end;
  }
`;

const ResidentialsResidentialBodyTitle = styled.h4`
  font-family: "Averta CY";
  font-style: normal;
  font-weight: 400;
  // font-size: 17px;
  font-size: ${pxIntoRem(17)};
  line-height: 21px;
  color: #000000;
`;

const ResidentialsResidentialBodyArea = styled.span`
  font-family: "Averta CY";
  font-style: normal;
  font-weight: 400;
  // font-size: 12px;
  font-size: ${pxIntoRem(12)};
  line-height: 15px;
  color: #737373;
`;

const ResidentialsResidentialBodyAreaImage = styled.img`
  margin-right: 2px;
  width: 9px;
  height: 11px;
`;

const ResidentialsResidentialBodyCost = styled.span`
  font-family: "Averta CY";
  font-style: normal;
  font-weight: 700;
  // font-size: 17px;
  font-size: ${pxIntoRem(17)};
  line-height: 21px;
  text-align: right;
  color: #2b3d63;
`;
const Residentials: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentSity = useSelector((state: RootState) => state.residentials.currentSity);
  const [residentials, setResidentials] = useState<IResidential[]>([]);

  useEffect(() => {
    residentialsService
      .getResidentials(currentSity)
      .then((data) => setResidentials(data.result))
      .catch(console.log);
  }, []);

  function getCorrectSityName(city: City): string {
    switch (city) {
      case City.Krasnodar:
        return "Краснодаре";
      case City.Anapa:
        return "Анапе";
      case City.Sochi:
        return "Сочи";
    }
  }

  function redirectToResidential(residential: IResidential) {
    dispatch(setCurrentResidential(residential));

    statisticService
      .sendStatistic({
        chat_id: Number(localStorage.getItem("userId")),
        // min_area: residential.filter_data.min_area,
        // max_area: residential.filter_data.max_area,
        // min_price: residential.filter_data.min_price,
        // max_price: residential.filter_data.max_price,
        complex_id: residential.id,
        city: currentSity,
      })
      .catch(console.log);

    navigate(`/residential`);
  }

  return (
    <DefaultLayout maxWidth={382}>
      <ResidentialsBlock>
        <ResidentialsHeader>
          <ResidentialsHeaderBack onClick={() => navigate(-1)}>
            <ResidentialsHeaderBackImage src="/images/arrow-left.svg" alt="back" />
          </ResidentialsHeaderBack>
          <ResidentialsHeaderTitle>Жилые комплексы в {getCorrectSityName(currentSity)}</ResidentialsHeaderTitle>
        </ResidentialsHeader>
        <ResidentialsList>
          {residentials.map((residential) => {
            return (
              <ResidentialsResidential key={residential.id} onClick={() => redirectToResidential(residential)}>
                <ResidentialsResidentialImageBlock>
                  <ResidentialsResidentialImage src={residential.image} alt="apartment-image" />
                </ResidentialsResidentialImageBlock>
                <ResidentialsResidentialBody>
                  <ResidentialsResidentialBodyColumn>
                    <ResidentialsResidentialBodyTitle>{residential.name}</ResidentialsResidentialBodyTitle>
                    <ResidentialsResidentialBodyArea>
                      <ResidentialsResidentialBodyAreaImage src="/images/area.svg" alt="area" /> {residential.location}
                    </ResidentialsResidentialBodyArea>
                  </ResidentialsResidentialBodyColumn>
                  <ResidentialsResidentialBodyColumn>
                    <ResidentialsResidentialBodyCost>от {formatCost(residential.min_price)} ₽</ResidentialsResidentialBodyCost>
                  </ResidentialsResidentialBodyColumn>
                </ResidentialsResidentialBody>
              </ResidentialsResidential>
            );
          })}
        </ResidentialsList>
      </ResidentialsBlock>
    </DefaultLayout>
  );
};

export default Residentials;
