import { createStore, combineReducers } from 'redux'
import residentialsReducer from './residentialsReducer'

const store = createStore(combineReducers({
    residentials: residentialsReducer,
}))

export type RootState = ReturnType<typeof store.getState>

export type Action<T> = {
    type: T
    payload: any
}

export default store