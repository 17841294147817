import { FC, useState } from "react";
import styled from "styled-components";
import { IResidentialApartment } from "../../types/residentials";
import { formatCost } from "../../utils/costFormatters";
import pxIntoRem from "../../utils/remIntoPx";

const ResidentialApartmentsBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 28px;
`;

const ResidentialApartment = styled.div`
  display: flex;
  width: 100%;
  margin-top: 40px;
  &:first-of-type {
    margin-top: 0px;
  }
`;

const ResidentialApartmentImageBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 144px;
  height: auto;
  @media (max-width: 360px) {
    width: 124px;
  }
`;

const ResidentialApartmentImage = styled.img`
  width: 100%;
  height: 100%;
`;

const ResidentialApartmentBody = styled.div`
  max-width: 218px;
  height: auto;
  flex-grow: 1;
  margin-left: 6px;
`;

const ResidentialApartmentTitle = styled.h3`
  font-family: "Averta CY";
  font-style: normal;
  font-weight: 400;
  // font-size: 15px;
  font-size: ${pxIntoRem(15)};
  line-height: 19px;
  color: #000000;
`;

const ResidentialApartmentCost = styled.h4`
  font-family: "Averta CY";
  font-style: normal;
  font-weight: 700;
  // font-size: 14px;
  font-size: ${pxIntoRem(14)};
  line-height: 17px;
  color: #2b3d63;
  margin-top: 5px;
`;

const ResidentialApartmentList = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  list-style: none;
  margin-top: 5px;
`;

const ResidentialApartmentListItem = styled.li`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 4px;
  &:first-of-type {
    margin-top: 0px;
  }
`;

const ResidentialApartmentListItemName = styled.span`
  font-family: "Averta CY";
  font-style: normal;
  font-weight: 400;
  // font-size: 10px;
  font-size: ${pxIntoRem(10)};
  line-height: 12px;
  color: #737373;
`;

const ResidentialApartmentListItemValue = styled.span`
  font-family: "Averta CY";
  font-style: normal;
  font-weight: 700;
  // font-size: 14px;
  font-size: ${pxIntoRem(14)};
  line-height: 17px;
  text-align: right;
  color: #000000;
`;

const ResidentialApartmentButton = styled.button`
  background: #f6c944;
  border-radius: 20px;
  font-family: "Averta CY";
  font-style: normal;
  font-weight: 400;
  // font-size: 13px;
  font-size: ${pxIntoRem(13)};
  line-height: 16px;
  text-align: center;
  color: #000000;
  width: 100%;
  height: 40px;
  cursor: pointer;
  margin-top: 11px;
`;

interface IResidentialApartments {
  apartments: IResidentialApartment[];
  onClick: (apartmentId: number) => any;
}

const ResidentialApartments: FC<IResidentialApartments> = ({ apartments, onClick }) => {
  return (
    <ResidentialApartmentsBlock>
      {apartments.map((apartment) => {
        return (
          <ResidentialApartment key={apartment.id}>
            <ResidentialApartmentImageBlock>
              <ResidentialApartmentImage alt="apartment-image" src={apartment.layout} />
            </ResidentialApartmentImageBlock>
            <ResidentialApartmentBody>
              <ResidentialApartmentTitle>Квартира №{apartment.id}</ResidentialApartmentTitle>
              <ResidentialApartmentCost>{formatCost(apartment.price)} ₽</ResidentialApartmentCost>
              <ResidentialApartmentList>
                <ResidentialApartmentListItem>
                  <ResidentialApartmentListItemName>Этаж</ResidentialApartmentListItemName>
                  <ResidentialApartmentListItemValue>{apartment.floor}</ResidentialApartmentListItemValue>
                </ResidentialApartmentListItem>
                <ResidentialApartmentListItem>
                  <ResidentialApartmentListItemName>Площадь, м2</ResidentialApartmentListItemName>
                  <ResidentialApartmentListItemValue>{apartment.total_area}</ResidentialApartmentListItemValue>
                </ResidentialApartmentListItem>
                <ResidentialApartmentListItem>
                  <ResidentialApartmentListItemName>Количество комнат</ResidentialApartmentListItemName>
                  <ResidentialApartmentListItemValue>{apartment.rooms}</ResidentialApartmentListItemValue>
                </ResidentialApartmentListItem>
              </ResidentialApartmentList>
              <ResidentialApartmentButton onClick={() => onClick(apartment.id)}>Подробнее</ResidentialApartmentButton>
            </ResidentialApartmentBody>
          </ResidentialApartment>
        );
      })}
    </ResidentialApartmentsBlock>
  );
};

export default ResidentialApartments;
