import axios from "axios"
import config from "../configs/appConfig"
import { IResidential, IResidentialApartment } from "../types/residentials"
import City from "../types/city"

interface IGetResidentialsResponse {
    result: IResidential[]
}

interface IGetApartmentsProps {
    complex_id: number
    min_price: number
    max_price: number
    min_area: number
    max_area: number
    not_first_floor: number
    not_last_floor: number
    rooms: number[]
}

interface IGetApartmentsResponse {
    result: IResidentialApartment[]
}

class ResidentialsService {
    async getResidentials(city: City): Promise<IGetResidentialsResponse> {
        const res = await axios.get(config.apiUrl + `/complexes_list?account_id=1&bot_city=${city}`)
        return res.data
    }

    async getResidentialApartments(props: IGetApartmentsProps): Promise<IGetApartmentsResponse> {
        const paramsString = this.encodeData({
            account_id: 1,
            complex_id: props.complex_id,
            min_price: props.min_price,
            max_price: props.max_price,
            min_area: props.min_area,
            max_area: props.max_area,
            not_first_floor: props.not_first_floor,
            not_last_floor: props.not_last_floor
        })

        const roomsString = props.rooms.map((room) => {
            return "&rooms[]=" + room
        }).join("")


        const resultQueryString = paramsString + roomsString

        const res = await axios.get(config.apiUrl + `/flats?${resultQueryString}`)
        return res.data
    }

    encodeData(data: any) {
        return Object.keys(data).map((key) => [key, data[key]].map(encodeURIComponent).join("=")).join("&");
    }
}

export default new ResidentialsService()