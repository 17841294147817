import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import DefaultLayout from "../layouts/DefaultLayout";
import "./styles/residential.css";
import pxIntoRem from "../utils/remIntoPx";
import ResidentialSlider from "../components/Residential/ResidentialSlider";
import ResidentialFilters from "../components/Residential/ResidentialFilters";
import ResidentialApartments from "../components/Residential/ResidentialApartments";
import residentialsService from "../services/residentialsService";
import { IResidentialApartment } from "../types/residentials";
import { formatCost } from "../utils/costFormatters";
import { RootState } from "../store";
import statisticService from "../services/statisticService";

const ResidentialBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  flex-grow: 1;
  padding-bottom: 60px;
`;

const ResidentialContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 388px;
  width: 100%;
  margin-top: 22px;
`;

const ResidentialTitle = styled.h1`
  font-family: "Averta CY";
  font-style: normal;
  font-weight: 400;
  // font-size: 23px;
  font-size: ${pxIntoRem(23)};
  line-height: 28px;
  color: #000000;
`;

const ResidentialCost = styled.h2`
  font-family: "Averta CY";
  font-style: normal;
  font-weight: 700;
  // font-size: 23px;
  font-size: ${pxIntoRem(23)};
  line-height: 28px;
  color: #2b3d63;
  margin-top: 6px;
  margin-left: 3px;
  letter-spacing: 0.2px;
`;

const ResidentialDiscount = styled(ResidentialCost)`
  margin-top: 8px;
`;

const ResidentialText = styled.p`
  font-family: "Averta CY";
  font-style: normal;
  font-weight: 400;
  // font-size: 15px;
  font-size: ${pxIntoRem(15)};
  line-height: 19px;
  color: #737373;
  margin-top: 23px;
`;

const Residential: FC = () => {
  const residential = useSelector((state: RootState) => state.residentials.currentResidential);
  const residentialFilter = useSelector((state: RootState) => state.residentials.currentResidential?.filter_data);
  const currentSity = useSelector((state: RootState) => state.residentials.currentSity);

  const [minCost, setMinCost] = useState(residentialFilter.max_price);
  const [maxCost, setMaxCost] = useState(residentialFilter.max_price);
  const [minArea, setMinArea] = useState(residentialFilter.min_area);
  const [maxArea, setMaxArea] = useState(residentialFilter.max_area);

  const [rooms, setRooms] = useState<number[]>([]);
  const [notFirst, setNotFirst] = useState<number>(1);
  const [notLast, setNotLast] = useState<number>(0);

  const [residentialApartments, setResidentialApartments] = useState<IResidentialApartment[]>([]);

  useEffect(() => {
    if (residentialFilter) {
      residentialsService
        .getResidentialApartments({
          min_area: minArea,
          max_area: maxArea,
          min_price: minCost,
          max_price: maxCost,
          rooms,
          not_first_floor: notFirst,
          not_last_floor: notLast,
          complex_id: residential.id,
        })
        .then((data) => {
          setResidentialApartments(data.result);
        })
        .catch(console.log);

      statisticService
        .sendStatistic({
          chat_id: Number(localStorage.getItem("userId")),
          min_area: minArea,
          max_area: maxArea,
          min_price: minCost,
          max_price: maxCost,
          rooms,
          complex_id: residential.id,
          city: currentSity,
        })
        .catch(console.log);
    }
  }, [minArea, maxArea, minCost, maxCost, rooms, notFirst, notLast]);

  function onCostChange(minCost: number, maxCost: number) {
    setMinCost(minCost);
    setMaxCost(maxCost);
  }

  function onAreaChange(minArea: number, maxArea: number) {
    setMinArea(minArea);
    setMaxArea(maxArea);
  }

  function onButtonsFilterChange(rooms: number[], notFirst: number, notLast: number) {
    setRooms(rooms);
    setNotFirst(notFirst);
    setNotLast(notLast);
  }

  function contactManager(apartmentId: number) {
    statisticService
      .sendStatistic({
        chat_id: Number(localStorage.getItem("userId")),
        min_area: minArea,
        max_area: maxArea,
        min_price: minCost,
        max_price: maxCost,
        rooms,
        complex_id: residential.id,
        city: currentSity,
        flat_id: apartmentId,
      })
      .then(() => {
        Telegram.WebApp.sendData("contact-manager");
      })
      .catch(console.log);
  }

  return (
    <DefaultLayout maxWidth={428} overContainerBlock={<ResidentialSlider photos={residential.photos ?? []} />}>
      <ResidentialBlock>
        <ResidentialContent>
          <ResidentialTitle>{residential.name}</ResidentialTitle>
          <ResidentialCost>от {formatCost(residential.min_price)} ₽</ResidentialCost>
          {residential.sale_bot && <ResidentialDiscount>{residential.sale_bot}</ResidentialDiscount>}
          <ResidentialText>
            {/* ЖК “Fresh” - это комплексная застройка <br />
            из семи 12-этажных домов, в долине реки Кудепста, <br />
            с собственной набережной, видом из окон на море <br /> и горы, многоуровневым паркингом с эксплуатиру- <br />
            емой кровлей, современными детскими <br /> и спортивными площадками */}
            {residential.description}
          </ResidentialText>
          <ResidentialFilters
            filter={{ ...residentialFilter, notFirst, notLast, rooms }}
            onCostChange={onCostChange}
            onAreaChange={onAreaChange}
            onButtonsFilterChange={onButtonsFilterChange}
          />
          <ResidentialApartments apartments={residentialApartments} onClick={contactManager} />
        </ResidentialContent>
      </ResidentialBlock>
    </DefaultLayout>
  );
};

export default Residential;
