import { FC, useMemo, useState } from "react";
import styled from "styled-components";
import { IResidential } from "../../types/residentials";
import pxIntoRem from "../../utils/remIntoPx";
import ResidentialButtonsFilter from "./Filters/ResidentialButtonsFilter";
import ResidentialRangeFilter from "./Filters/ResidentialRangeFilter";

const ResidentialFiltersBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 377px;
  width: 100%;
  margin: 0 auto;
  margin-top: 23px;
`;

const MIN_COST = 0;
const MAX_COST = 100000000;

const MIN_AREA = 0;
const MAX_AREA = 700;

interface IResidentialFiltersProps {
  filter: IResidential["filter_data"] & { notLast: number; notFirst: number; rooms: number[] };
  onCostChange(minCost: number, maxCost: number): any;
  onAreaChange(minCost: number, maxCost: number): any;
  onButtonsFilterChange(rooms: number[], notFirst: number, notLast: number): any;
}

const ResidentialFilters: FC<IResidentialFiltersProps> = ({ filter, onCostChange, onAreaChange, onButtonsFilterChange }) => {
  return (
    <ResidentialFiltersBlock>
      <ResidentialRangeFilter
        title="Стоимость, млн ₽"
        defaultMin={filter.min_price}
        defaultMax={filter.max_price}
        minValue={filter.min_price}
        maxValue={filter.max_price}
        needFormat={true}
        onChange={onCostChange}
        step={1}
        numberFields={false}
      />
      <ResidentialRangeFilter
        title="Площадь, м2"
        defaultMin={filter.min_area}
        defaultMax={filter.max_area}
        minValue={filter.min_area}
        maxValue={filter.max_area}
        needFormat={false}
        onChange={onAreaChange}
        gap={1}
        step={0.01}
        numberFields={true}
      />
      <ResidentialButtonsFilter filter={filter} onChange={onButtonsFilterChange} />
    </ResidentialFiltersBlock>
  );
};

export default ResidentialFilters;
