import styled from "styled-components";
import { Routes, Route } from "react-router-dom";
import Index from "./pages/Index";
import Residentials from "./pages/Residentials";
import Residential from "./pages/Residential";

const AppBlock = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
`;

function App() {
  return (
    <AppBlock>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/residentials" element={<Residentials />} />
        <Route path="/residential" element={<Residential />} />
      </Routes>
    </AppBlock>
  );
}

export default App;
