import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import store from "./store";
import App from "./App";

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    border: none;
    margin: 0;
    padding: 0;
  }

  body, html {
    width: 100%;
    height: 100%;
    font-size: 16px;
    @media (max-width: 400px) {
      font-size: 14px;
    }
    @media (max-width: 340px) {
      font-size: 13.5px;
    }
  }

  #root { 
    height: auto;
    min-height: 100%;
    width: 100%;
    overflow: hidden;
  }

  a {
    text-decoration: none;
  }

  input {
    outline: none
  }

  @font-face {
    font-family: 'Averta CY';
    src: url('/fonts/AvertaCY-Bold.eot');
    src: url('/fonts/AvertaCY-Bold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/AvertaCY-Bold.woff2') format('woff2'),
        url('/fonts/AvertaCY-Bold.woff') format('woff'),
        url('/fonts/AvertaCY-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
      font-family: 'Averta CY';
      src: url('/fonts/AvertaCY-Extrabold.eot');
      src: url('/fonts/AvertaCY-Extrabold.eot?#iefix') format('embedded-opentype'),
          url('/fonts/AvertaCY-Extrabold.woff2') format('woff2'),
          url('/fonts/AvertaCY-Extrabold.woff') format('woff'),
          url('/fonts/AvertaCY-Extrabold.ttf') format('truetype');
      font-weight: bold;
      font-style: normal;
      font-display: swap;
  }

  @font-face {
      font-family: 'Averta CY';
      src: url('/fonts/AvertaCY-Light.eot');
      src: url('/fonts/AvertaCY-Light.eot?#iefix') format('embedded-opentype'),
          url('/fonts/AvertaCY-Light.woff2') format('woff2'),
          url('/fonts/AvertaCY-Light.woff') format('woff'),
          url('/fonts/AvertaCY-Light.ttf') format('truetype');
      font-weight: 300;
      font-style: normal;
      font-display: swap;
  }

  @font-face {
      font-family: 'Averta CY';
      src: url('/fonts/AvertaCY-Regular.eot');
      src: url('/fonts/AvertaCY-Regular.eot?#iefix') format('embedded-opentype'),
          url('/fonts/AvertaCY-Regular.woff2') format('woff2'),
          url('/fonts/AvertaCY-Regular.woff') format('woff'),
          url('/fonts/AvertaCY-Regular.ttf') format('truetype');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
  }

  @font-face {
      font-family: 'Averta CY';
      src: url('/fonts/AvertaCY-Semibold.eot');
      src: url('/fonts/AvertaCY-Semibold.eot?#iefix') format('embedded-opentype'),
          url('/fonts/AvertaCY-Semibold.woff2') format('woff2'),
          url('/fonts/AvertaCY-Semibold.woff') format('woff'),
          url('/fonts/AvertaCY-Semibold.ttf') format('truetype');
      font-weight: 600;
      font-style: normal;
      font-display: swap;
  }
`;

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <Provider store={store}>
    <Router>
      <GlobalStyles />
      <App />
    </Router>
  </Provider>
);
