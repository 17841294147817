import { FC } from "react";
import styled from "styled-components";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { useNavigate } from "react-router-dom";
import { IResidential } from "../../types/residentials";

const ResidentialSliderBlock = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  max-height: 231px;
  max-width: 428px;
  height: 57vmin;
`;

const ResidentialSliderImage = styled.img`
  width: 100%;
  height: 100%;
`;

const ResidentialBack = styled.button`
  position: absolute;
  left: 20px;
  top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 100%;
  z-index: 999999;
  cursor: pointer;
`;

const ResidentialBackImage = styled.img`
  width: 13px;
  height: 11px;
  position: absolute;
  top: 15px;
`;

interface IResidentialSliderProps {
  photos: IResidential["photos"];
}

const ResidentialSlider: FC<IResidentialSliderProps> = ({ photos }) => {
  const navigate = useNavigate();
  return (
    <ResidentialSliderBlock>
      <Swiper
        slidesPerView={1}
        modules={[Pagination]}
        pagination={{
          el: ".pagination",
          clickable: true,
          bulletClass: "pagination__dot",
          bulletActiveClass: "pagination__dot_active",
        }}
      >
        {photos.map((photo) => {
          return (
            <SwiperSlide key={photo.id}>
              <ResidentialSliderImage src={photo.path} alt="photo" />
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div className="pagination"></div>
      <ResidentialBack onClick={() => navigate(-1)}>
        <ResidentialBackImage src="/images/arrow-left.svg" alt="back" />
      </ResidentialBack>
    </ResidentialSliderBlock>
  );
};

export default ResidentialSlider;
