import { Action } from ".."
import { IResidential } from "../../types/residentials"
import City from "../../types/city"
import ActionTypes from "./types"

interface IState {
    currentResidential: IResidential | null
    currentSity: City
}

const defaultState: IState = {
    currentResidential: null,
    currentSity: City.Krasnodar
}

function residentialsReducer(state: IState = defaultState, action: Action<ActionTypes>): IState {
    switch (action.type) {
        case ActionTypes.SET_CURRENT_SITY:
            return { ...state, currentSity: action.payload }
        case ActionTypes.SET_CURRENT_RESIDENTIAL:
            return { ...state, currentResidential: action.payload }
        default:
            return state
    }
}

export default residentialsReducer