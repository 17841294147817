import { FC, PropsWithChildren } from "react";
import styled from "styled-components";
import Container from "../components/Container";

interface IDefaultLayoutProps {
  maxWidth?: number;
  overContainerBlock?: React.ReactNode;
  absoluteBlock?: React.ReactNode;
}

const DefaultLayoutBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const DefaultLayout: FC<PropsWithChildren<IDefaultLayoutProps>> = ({ children, maxWidth, overContainerBlock, absoluteBlock }) => {
  return (
    <DefaultLayoutBlock>
      {overContainerBlock}
      {absoluteBlock}
      <Container maxWidth={maxWidth}>{children}</Container>
    </DefaultLayoutBlock>
  );
};

export default DefaultLayout;
