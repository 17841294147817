const reverseString = (str: string) => str.split("").reverse().join("");

function formatCost(cost: number) {
    const str = reverseString(String(cost));
    const resultArr = [];
    for (let i = 0; i < str.length; i += 3) {
        const substring = reverseString(str.slice(i, i + 3));
        resultArr.unshift(substring);
    }
    return resultArr.join(" ");
}

function deformatCost(cost: string): number {
    return Number(cost.split(" ").join(""));
}

export { formatCost, deformatCost }