import { FC, useState } from "react";
import { Navigate, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import pxIntoRem from "../utils/remIntoPx";
import DefaultLayout from "../layouts/DefaultLayout";
import City from "../types/city";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentSity } from "../store/residentialsReducer/actions";
import { RootState } from "../store";
import statisticService from "../services/statisticService";

const IndexBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 9;
`;

const IndexBackgroundRect = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 283px;
  background-color: #2b3d63;
`;

const IndexBeeBuilding = styled.img`
  position: absolute;
  right: -126px;
  top: 95px;
`;

const IndexHeader = styled.header`
  width: 100%;
  padding: 25px 0px 20px;
  border-bottom: 1px solid #4d5f85;
  z-index: 9;
`;

const IndexHeaderLogoBlock = styled.div`
  width: 96px;
  height: 36px;
`;

const IndexHeaderLogo = styled.img`
  width: 100%;
  height: 100%;
`;

const IndexTitleBlock = styled.div`
  width: 100%;
  padding: 34px 0px 52px;
  z-index: 9;
`;

const IndexTitle = styled.h1`
  position: relative;
  font-family: "Averta CY";
  font-style: normal;
  font-weight: 800;
  // font-size: 30px;
  font-size: ${pxIntoRem(30)};
  line-height: 37px;
  color: #ffffff;
  sup {
    font-size: ${pxIntoRem(19)};
    position: absolute;
    bottom: 8px;
  }
`;

const IndexBannerBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 35px 12px;
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 15px;
  position: relative;
  z-index: 9;
`;

const IndexBannerTitle = styled.h3`
  font-family: "Averta CY";
  font-style: normal;
  font-weight: 600;
  // font-size: 18px;
  font-size: ${pxIntoRem(18)};
  line-height: 22px;
  color: #2b3d63;
`;

const IndexBannerText = styled.p`
  font-family: "Averta CY";
  font-style: normal;
  font-weight: 400;
  // font-size: 15px;
  font-size: ${pxIntoRem(15)};
  line-height: 17px;
  color: #2b3d63;
  margin-top: 21px;
  letter-spacing: -0.1px;
  padding-right: 10px;
  &:last-of-type {
    line-height: 19px;
  }
`;

const IndexBannerButton = styled.button`
  display: block;
  font-family: "Averta CY";
  font-style: normal;
  font-weight: 400;
  // font-size: 15px;
  font-size: ${pxIntoRem(15)};
  line-height: 19px;
  text-align: center;
  color: #000000;
  padding: 20px 39px;
  border: 1px solid #f6c944;
  border-radius: 40px;
  background-color: transparent;
  cursor: pointer;
  margin: 0 auto;
  margin-top: 35px;
  flex-grow: 1;
  flex-shrink: 0;
  width: 100%;
  max-width: 312px;
`;

const IndexChooseBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
`;

const IndexChooseTitle = styled.h2`
  font-family: "Averta CY";
  font-style: normal;
  font-weight: 700;
  // font-size: 20px;
  font-size: ${pxIntoRem(20)};
  line-height: 25px;
  color: #000000;
`;

const IndexChooseSities = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 25px;
`;

interface IIndexChooseSity {
  isActive: boolean;
}

const IndexChooseSity = styled.button<IIndexChooseSity>`
  font-family: "Averta CY";
  font-style: normal;
  font-weight: 400;
  // font-size: 15px;
  font-size: ${pxIntoRem(15)};
  line-height: 19px;
  text-align: center;
  color: #000000;
  padding: 20px 0px 21px;
  border: 1px solid ${({ isActive }) => (isActive ? "#f6c944" : "#D9D9D9")};
  border-radius: 40px;
  background-color: transparent;
  flex-grow: 1;
  cursor: pointer;
  max-width: 101px;
  &:first-of-type {
    max-width: 118px;
  }
  &:last-of-type {
    max-width: 150px;
  }

  @media (max-width: 400px) {
    &:last-of-type {
      max-width: 125px;
    }
  }

  @media (max-width: 370px) {
    max-width: 91px;
    &:first-of-type {
      max-width: 108px;
    }
  }

  @media (max-width: 350px) {
    max-width: 80px;
    &:first-of-type {
      max-width: 98px;
    }
    &:last-of-type {
      max-width: 130px;
    }
  }
`;

const IndexChooseButton = styled.button`
  font-family: "Averta CY";
  font-style: normal;
  font-weight: 600;
  // font-size: 15px;
  font-size: ${pxIntoRem(15)};
  line-height: 19px;
  text-align: center;
  color: #000000;
  width: 100%;
  height: 60px;
  background-color: #f6c944;
  border-radius: 40px;
  margin-top: 34px;
  margin-bottom: 60px;
  cursor: pointer;
`;

const Index: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [query] = useSearchParams();
  const currentSity = useSelector((state: RootState) => state.residentials.currentSity);
  const [chosenSity, setChosenCity] = useState<City>(currentSity ?? City.Krasnodar);

  const contactManager = () => Telegram.WebApp.sendData("contact-manager");

  function redirectToResidentials() {
    const userId = query.get("userId");

    if (userId && localStorage.getItem("userId") !== userId) {
      console.log(userId);
      localStorage.setItem("userId", userId);
    }

    dispatch(setCurrentSity(chosenSity));

    statisticService
      .sendStatistic({
        chat_id: Number(userId),
        city: chosenSity,
      })
      .catch(console.log);

    navigate(`/residentials`);
  }

  return (
    <DefaultLayout maxWidth={382} absoluteBlock={<IndexBackgroundRect />}>
      <IndexBlock>
        <IndexHeader>
          <IndexHeaderLogoBlock>
            <IndexHeaderLogo src="/images/logo.svg" alt="logo" />
          </IndexHeaderLogoBlock>
        </IndexHeader>
        <IndexTitleBlock>
          <IndexTitle>
            Скидка до 20% <br />
            на квартиры от 20м <sup>2</sup>
          </IndexTitle>
        </IndexTitleBlock>
        <IndexBeeBuilding src="/images/bee-building.png" alt="bee-building" />
        <IndexBannerBlock>
          <IndexBannerTitle>
            Холдинг AVA Group — один из лидеров отрасли в сфере строительства и управления объектами недвижимости. Планируя каждый проект, мы думаем о том, как будут жить, что
            чувствовать их жители.
          </IndexBannerTitle>
          {/* <IndexBannerText>
            Холдинг AVA Group — один из лидеров отрасли в сфере строительства и управления объектами недвижимости. Планируя каждый проект, мы думаем о том, как будут жить, что
            чувствовать их жители.
          </IndexBannerText> */}
          <IndexBannerText>
            Мы думаем о создании цельной среды, в которой не нужно тратить лишнего времени на повседневные хлопоты и можно дарить его близким, думать о комфорте, здоровье и детях,
            безопасности и экологии. Мы заботимся о развитии — росте благополучия и реализации стремлений наших клиентов.
          </IndexBannerText>
          <IndexBannerButton onClick={contactManager}>Связаться с менеджером</IndexBannerButton>
        </IndexBannerBlock>
        <IndexChooseBlock>
          <IndexChooseTitle>Выбрать город</IndexChooseTitle>
          <IndexChooseSities>
            <IndexChooseSity isActive={chosenSity === City.Anapa} onClick={() => setChosenCity(City.Anapa)}>
              Анапа
            </IndexChooseSity>
            <IndexChooseSity isActive={chosenSity === City.Sochi} onClick={() => setChosenCity(City.Sochi)}>
              Сочи
            </IndexChooseSity>
            <IndexChooseSity isActive={chosenSity === City.Krasnodar} onClick={() => setChosenCity(City.Krasnodar)}>
              Краснодар
            </IndexChooseSity>
          </IndexChooseSities>
          <IndexChooseButton onClick={() => redirectToResidentials()}>Подобрать квартиру</IndexChooseButton>
        </IndexChooseBlock>
      </IndexBlock>
    </DefaultLayout>
  );
};

export default Index;
