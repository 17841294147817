import { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { IResidential } from "../../../types/residentials";
import pxIntoRem from "../../../utils/remIntoPx";

const ResidentialFilter = styled.div`
  width: 100%;
  margin-top: 17px;
  &:first-of-type {
    margin-top: 0px;
  }
`;

const ResidentialFilterTitle = styled.span`
  font-family: "Averta CY";
  font-style: normal;
  font-weight: 400;
  // font-size: 10px;
  font-size: ${pxIntoRem(10)};
  line-height: 12px;
  color: #737373;
`;

interface IResidentialFilterBody {
  isOutline?: boolean;
}

const ResidentialFilterBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  ${({ isOutline }: IResidentialFilterBody) => isOutline && "border: 1px solid #d9d9d9;"}
  border-radius: 5px;
  height: 43px;
  margin-top: 7px;
`;

const ResidentialFilterColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 0;
`;

const ResidentialFilterColumnSplitter = styled.div`
  width: 1px;
  height: 30px;
  background-color: #d9d9d9;
`;

const ResidentialFilterColumnInput = styled.input`
  font-family: "Averta CY";
  font-style: normal;
  font-weight: 600;
  // font-size: 19px;
  font-size: ${pxIntoRem(19)};
  line-height: 23px;
  text-align: center;
  color: #000000;
  max-width: 128px;
`;

const ResidentialFilterRange = styled.div`
  position: absolute;
  bottom: 0px;
  height: 1px;
  width: 99.5%;
  // background-color: #f6c944;
`;

const ResidentialFilterRangeProgress = styled.div`
  position: absolute;
  bottom: -1px;
  height: 1.5px;
  width: 100%;
  background-color: #f6c944;
`;

const ResidentialFilterRangeInputs = styled.div`
  display: block;
  // width: 100%;
  width: 103.5%;
  position: absolute;
  bottom: -1px;
`;

const ResidentialFilterRangeInput = styled.input`
  display: block;
  width: 100%;
  height: 1px;
  background: none;
  position: absolute;

  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  pointer-events: none;

  &::-webkit-slider-thumb {
    pointer-events: all;
    -webkit-appearance: none;
    height: 11px;
    width: 11px;
    border-radius: 100%;
    background: #ffffff;
    border: 1px solid #f6c944;
    cursor: pointer;
  }
  &::-moz-range-thumb {
    pointer-events: all;
    -webkit-appearance: none;
    height: 11px;
    width: 11px;
    border-radius: 100%;
    background: #ffffff;
    border: 1px solid #f6c944;
    cursor: pointer;
  }
  &::-ms-thumb {
    pointer-events: all;
    -webkit-appearance: none;
    height: 11px;
    width: 11px;
    border-radius: 100%;
    background: #ffffff;
    border: 1px solid #f6c944;
    cursor: pointer;
  }
`;

interface IActivable {
  isActive?: boolean;
}

const ResidentialFilterRoom = styled.button<IActivable>`
  background-color: transparent;
  height: 39px;
  width: 39px;
  border-radius: 100%;
  border: 1px solid ${({ isActive }) => (isActive ? "#F6C944" : "#d9d9d9")};
  font-family: "Averta CY";
  font-style: normal;
  font-weight: 600;
  font-size: ${pxIntoRem(17)};
  line-height: 21px;
  text-align: center;
  color: #000000;
  cursor: pointer;
`;

const ResidentialFilterButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const ResidentialFilterNot = styled.button<IActivable>`
  background-color: transparent;
  height: 39px;
  width: 87px;
  border-radius: 20px;
  border: 1px solid ${({ isActive }) => (isActive ? "#F6C944" : "#d9d9d9")};
  font-family: "Averta CY";
  font-style: normal;
  font-weight: 600;
  font-size: ${pxIntoRem(10)};
  line-height: 112%;
  text-align: center;
  color: #000000;
  cursor: pointer;
  &:first-of-type {
    width: 89px;
  }

  @media (max-width: 355px) {
    width: 77px;
    &:first-of-type {
      width: 79px;
    }
  }
`;

interface IResidentialButtonsFilterProps {
  filter: IResidential["filter_data"] & { rooms: number[]; notFirst: number; notLast: number };
  onChange(rooms: number[], notFirst: number, notLast: number): any;
}
const ResidentialButtonsFilter: FC<IResidentialButtonsFilterProps> = ({ onChange, filter }) => {
  const [rooms, setRooms] = useState<number[]>(filter.rooms);
  const [notFirst, setNotFirst] = useState<number>(filter.notFirst);
  const [notLast, setNotLast] = useState<number>(filter.notLast);

  function changeRooms(id: number) {
    setRooms((prev) => {
      if (prev.includes(id)) {
        return prev.filter((roomId) => roomId !== id);
      }
      return [...prev, id];
    });
  }

  function changeNotFirst() {
    setNotFirst((prev) => {
      return prev === 1 ? 0 : 1;
    });
  }

  function changeNotLast() {
    setNotLast((prev) => {
      return prev === 1 ? 0 : 1;
    });
  }

  useEffect(() => {
    onChange(rooms, notFirst, notLast);
  }, [rooms, notFirst, notLast]);

  return (
    <ResidentialFilter>
      <ResidentialFilterTitle>Комнатность</ResidentialFilterTitle>
      <ResidentialFilterBody>
        <ResidentialFilterColumn>
          <ResidentialFilterButtons>
            <ResidentialFilterRoom isActive={rooms.includes(0)} onClick={() => changeRooms(0)}>
              Ст
            </ResidentialFilterRoom>
            <ResidentialFilterRoom isActive={rooms.includes(1)} onClick={() => changeRooms(1)}>
              1
            </ResidentialFilterRoom>
            <ResidentialFilterRoom isActive={rooms.includes(2)} onClick={() => changeRooms(2)}>
              2
            </ResidentialFilterRoom>
            <ResidentialFilterRoom isActive={rooms.includes(3)} onClick={() => changeRooms(3)}>
              3
            </ResidentialFilterRoom>
            <ResidentialFilterNot isActive={!!notFirst} onClick={changeNotFirst}>
              не первый
              <br />
              этаж
            </ResidentialFilterNot>
            <ResidentialFilterNot isActive={!!notLast} onClick={changeNotLast}>
              не последний
              <br />
              этаж
            </ResidentialFilterNot>
          </ResidentialFilterButtons>
        </ResidentialFilterColumn>
      </ResidentialFilterBody>
    </ResidentialFilter>
  );
};

export default ResidentialButtonsFilter;
