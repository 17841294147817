import axios from "axios"
import config from "../configs/appConfig"
import City from "../types/city"

interface ISendStatisticProps {
    chat_id: number
    city?: City
    complex_id?: number
    min_price?: number
    max_price?: number
    min_area?: number
    max_area?: number
    rooms?: number[]
    flat_id?: number
}

type ISendStatisticPropsKey = keyof ISendStatisticProps

class StatisticService {
    async sendStatistic(props: ISendStatisticProps) {
        const formData = new FormData()
        const keys: ISendStatisticPropsKey[] = Object.keys(props) as any

        keys.forEach((key) => {
            if (!!props[key]) {
                formData.append(key, props[key].toString())
            }
        })

        const res = await axios.post(config.apiUrl + `/customer/${props.chat_id}`, formData, { method: "POST" })

        const data = res.data

        return data
    }
}

export default new StatisticService()