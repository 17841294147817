import { FC, PropsWithChildren } from "react";
import styled from "styled-components";

interface IContainerProps {
  maxWidth: number | undefined;
}

const ContainerBlock = styled.div`
  max-width: ${(props: IContainerProps) => props.maxWidth ?? 388}px;
  width: 100%;
  height: auto;
  flex-grow: 1;
  flex-shrink: 0;
  @media (max-width: 400px) {
    padding: 0px 10px;
  }
`;

const Container: FC<PropsWithChildren<IContainerProps>> = ({ children, maxWidth }) => {
  return <ContainerBlock maxWidth={maxWidth}>{children}</ContainerBlock>;
};

export default Container;
