import { FC, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { deformatCost, formatCost } from "../../../utils/costFormatters";
import pxIntoRem from "../../../utils/remIntoPx";

const ResidentialFilter = styled.div`
  width: 100%;
  margin-top: 17px;
  &:first-of-type {
    margin-top: 0px;
  }
`;

const ResidentialFilterTitle = styled.span`
  font-family: "Averta CY";
  font-style: normal;
  font-weight: 400;
  // font-size: 10px;
  font-size: ${pxIntoRem(10)};
  line-height: 12px;
  color: #737373;
`;

interface IResidentialFilterBody {
  isOutline?: boolean;
}

const ResidentialFilterBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  ${({ isOutline }: IResidentialFilterBody) => isOutline && "border: 1px solid #d9d9d9;"}
  border-radius: 5px;
  height: 43px;
  margin-top: 7px;
`;

const ResidentialFilterColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 0;
`;

const ResidentialFilterColumnSplitter = styled.div`
  width: 1px;
  height: 30px;
  background-color: #d9d9d9;
`;

const ResidentialFilterColumnInput = styled.input`
  font-family: "Averta CY";
  font-style: normal;
  font-weight: 600;
  // font-size: 19px;
  font-size: ${pxIntoRem(19)};
  line-height: 23px;
  text-align: center;
  color: #000000;
  max-width: 128px;
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const ResidentialFilterRange = styled.div`
  position: absolute;
  bottom: 0px;
  height: 1px;
  width: 99.5%;
  // background-color: #f6c944;
`;

const ResidentialFilterRangeProgress = styled.div`
  position: absolute;
  bottom: -1px;
  height: 1.5px;
  width: 100%;
  background-color: #f6c944;
`;

const ResidentialFilterRangeInputs = styled.div`
  display: block;
  // width: 100%;
  width: 103.5%;
  position: absolute;
  bottom: -1px;
`;

const ResidentialFilterRangeInput = styled.input`
  display: block;
  width: 100%;
  height: 1px;
  background: none;
  position: absolute;

  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  pointer-events: none;

  &::-webkit-slider-thumb {
    pointer-events: all;
    -webkit-appearance: none;
    height: 11px;
    width: 11px;
    border-radius: 100%;
    background: #ffffff;
    border: 1px solid #f6c944;
    cursor: pointer;
  }
  &::-moz-range-thumb {
    pointer-events: all;
    -webkit-appearance: none;
    height: 11px;
    width: 11px;
    border-radius: 100%;
    background: #ffffff;
    border: 1px solid #f6c944;
    cursor: pointer;
  }
  &::-ms-thumb {
    pointer-events: all;
    -webkit-appearance: none;
    height: 11px;
    width: 11px;
    border-radius: 100%;
    background: #ffffff;
    border: 1px solid #f6c944;
    cursor: pointer;
  }
`;

interface IFilterProps {
  title: string;
  minValue: number;
  maxValue: number;
  needFormat: boolean;
  defaultMin: number;
  defaultMax: number;
  gap?: number;
  step: number;
  numberFields: boolean;
  onChange(min: number, max: number): any;
}

const ResidentialRangeFilter: FC<IFilterProps> = ({ title, defaultMin, defaultMax, minValue, maxValue, needFormat, onChange, step, numberFields, gap = 5 }) => {
  const [min, setMin] = useState<number>(defaultMin);
  const [max, setMax] = useState<number>(defaultMax);

  function onMinInput(value: string) {
    const intValue = needFormat ? deformatCost(value || "0") : Number.parseFloat(value || "0");

    // if (intValue < minValue) {
    //   return;
    // }
    if (typeof intValue === "number") {
      console.log(intValue);

      setMin(intValue);
    }
  }

  function onMaxInput(value: string) {
    const intValue = needFormat ? deformatCost(value || "0") : Number.parseFloat(value || "0");

    // if (intValue > maxValue) {
    //   return;
    // }

    if (typeof intValue === "number") {
      setMax(intValue);
    }
  }

  function onMinRange(value: string) {
    const intValue = Number.parseFloat(value);

    if (intValue >= maxValue || intValue <= minValue) {
      return;
    }

    if (intValue >= max) {
      setMin(max - gap);
      return;
    }

    setMin(intValue);
  }

  function onMaxRange(value: string) {
    const intValue = Number.parseFloat(value);

    if (intValue >= maxValue || intValue <= minValue) {
      return;
    }

    if (intValue <= min) {
      setMax(min + gap);
      return;
    }

    setMax(intValue);
  }

  function onMinOut(value: string) {
    const intValue = needFormat ? deformatCost(value || "0") : Number.parseFloat(value);
    if (intValue > max || intValue < minValue || intValue > maxValue) {
      setMin(minValue);
    }
  }

  function onMaxOut(value: string) {
    const intValue = needFormat ? deformatCost(value || "0") : Number.parseFloat(value || "0");
    if (intValue < min || intValue < minValue || intValue > maxValue) {
      setMax(maxValue);
    }
  }

  useEffect(() => {
    onChange(min, max);
  }, [min, max]);

  const costProgressStyleObj = useMemo(() => {
    return getStyles(min, max, minValue, maxValue);
  }, [min, max]);

  function getStyles(min: number, max: number, minVal: number, maxVal: number) {
    const maxNum = maxVal - minVal;

    let right = 100 - ((max - minVal) / maxNum) * 100;
    let left = ((min - minVal) / maxNum) * 100;
    let width = 100 - right - left;

    if (width > 100) {
      width = 0;
    }

    if (left > 100) {
      left = 0;
      width = 0;
    }

    if (right > 100) {
      right = 0;
      width = 0;
    }

    return {
      right: right + "%",
      left: left + "%",
      width: width + "%",
    };
  }

  return (
    <ResidentialFilter>
      <ResidentialFilterTitle>{title}</ResidentialFilterTitle>

      <ResidentialFilterBody isOutline>
        <ResidentialFilterColumn>
          <ResidentialFilterColumnInput
            value={needFormat ? formatCost(min) : min}
            onChange={(e) => onMinInput(e.currentTarget.value)}
            onBlur={(e) => onMinOut(e.currentTarget.value)}
            type={numberFields ? "number" : "text"}
          />
        </ResidentialFilterColumn>

        <ResidentialFilterColumnSplitter />

        <ResidentialFilterColumn>
          <ResidentialFilterColumnInput
            value={needFormat ? formatCost(max) : max}
            onChange={(e) => onMaxInput(e.currentTarget.value)}
            onBlur={(e) => onMaxOut(e.currentTarget.value)}
            type={numberFields ? "number" : "text"}
          />
        </ResidentialFilterColumn>

        <ResidentialFilterRange>
          <ResidentialFilterRangeProgress style={costProgressStyleObj} />
        </ResidentialFilterRange>

        <ResidentialFilterRangeInputs>
          <ResidentialFilterRangeInput type={"range"} min={minValue} max={maxValue} value={min} onChange={(e) => onMinRange(e.currentTarget.value)} step={step} />
          <ResidentialFilterRangeInput type={"range"} min={minValue} max={maxValue} value={max} onChange={(e) => onMaxRange(e.currentTarget.value)} step={step} />
        </ResidentialFilterRangeInputs>
      </ResidentialFilterBody>
    </ResidentialFilter>
  );
};

export default ResidentialRangeFilter;
